import styled from "styled-components";
import { m, type Transition } from "framer-motion";

import { BOTTOM_NAVIGATION_SPACE_KEEPER_HEIGHT } from "@config/consts";

import { Arrow, Close } from "@ui/Assets/MyIcons";
import { rgba } from "@ui/helpers";

import { RootProps, StyledNoAnimation } from "./Drawer.types";

const transition: Transition = {
  duration: 0.15,
  ease: "easeInOut",
};

export const Root = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: ${({ theme }) => theme.zIndex.backdrop};
  overflow: hidden;
`;

export const BackdropBox = styled(m.div).attrs<StyledNoAnimation>(
  ({ $noAnimation }) =>
    !$noAnimation && {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: transition,
    },
)<StyledNoAnimation>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: ${({ theme }) => rgba(theme.palette.blue900, 0.4)};
  display: none;
  inset: 0;
  cursor: pointer;

  ${props => props.theme.breakpoints.tablet} {
    display: block;
  }
`;

export const Body = styled(m.div).attrs<
  Pick<Required<RootProps>, "placement"> & StyledNoAnimation
>(
  ({ placement, $noAnimation }) =>
    !$noAnimation && {
      initial: { x: placement === "left" ? "-101%" : "101%" },
      animate: { x: 0 },
      exit: { x: placement === "left" ? "-101%" : "101%" },
      transition: transition,
    },
)<Pick<Required<RootProps>, "placement"> & StyledNoAnimation>`
  --drawer-gap: ${({ theme }) => theme.spacing.spacing_16};
  --drawer-padding: ${({ theme }) => theme.spacing.spacing_24};

  height: 100%;
  z-index: 1;
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: var(--drawer-gap);
  background: ${({ theme }) => theme.palette.white};
  width: 100%;

  ${({ theme }) => theme.breakpoints.tablet} {
    --drawer-gap: ${({ theme }) => theme.spacing.spacing_32};
    --drawer-padding: ${({ theme }) => theme.spacing.spacing_40};

    width: 600px;
  }

  ${({ placement }) => placement === "left" && "left: 0"}
  ${({ placement }) => placement === "right" && "right: 0"}
`;

export const Header = styled.header`
  display: flex;
  color: ${({ theme }) => theme.palette.grey800};
  background: ${({ theme }) => theme.palette.white};

  position: relative;
  z-index: ${({ theme }) => theme.zIndex.backdrop};

  width: 100%;

  padding: ${({ theme }) => theme.spacing.spacing_24} var(--drawer-padding) 0;
`;

export const Cross = styled(Close).attrs(({ theme }) => ({
  width: theme.icons.sizes.base_x3,
}))``;

export const RevertedArrow = styled(Arrow).attrs(({ theme }) => ({
  width: theme.icons.sizes.base_x4,
}))`
  transform: rotate(180deg);
`;

export const Button = styled.button`
  padding: 0;

  :has(> ${Cross}) {
    margin-left: auto;
  }

  :has(${RevertedArrow}) {
    margin-right: auto;
  }
`;

export const Content = styled.div`
  overflow: auto;
  height: 100%;
  overscroll-behavior: contain;

  padding: ${({ theme }) =>
    `0 var(--drawer-padding) calc(${BOTTOM_NAVIGATION_SPACE_KEEPER_HEIGHT}px + ${theme.spacing.spacing_24})`};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${({ theme }) =>
      `0 var(--drawer-padding) ${theme.spacing.spacing_128}}`};
  }
`;
